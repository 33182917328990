@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'ES Rebond Grotesque';
  font-weight: 600;
  src: url(/public/fonts/ESRebondGrotesque-Semibold.ttf);
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  min-height: 100vh;
  overflow: hidden;
  max-width: 100%;
}

h1 {
  font-size: 20px;
  font-weight: 600;
}

h2 {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}

p,
a {
  color: #000;
}

.body-big {
  font-size: 18px;
  font-weight: 400;
  color: #6a6a6a;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.nav-wrap {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  font-size: 16px;
  padding: 48px 64px;
  height: 80px;

  border-bottom: 2px solid #f2f2f2;

  background: #fff;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.logo-wrap {
  font-family: 'ES Rebond Grotesque';
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.logo-wrap h1 {
  font-size: 24px;
}

.logo {
  height: 24px;
  margin-top: 4px;
}

.content-wrap {
  /* display: flex; */
  /* flex-direction: row; */
  flex-grow: 1;
}

.left {
  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  padding: 128px 64px 40px 64px;

  height: 100vh;
  width: 50vw;
  left: 0;
  top: 0;
}

.right {
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 80px;

  height: 100vh;
  width: 50vw;
  bottom: 0;
  margin: 0;
  right: 0;
  background-color: #f2f2f2;
}

form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome-message {
  margin-left: 8px;
  margin-bottom: 24px;
}

.input-wrap {
  position: relative;
  /* padding-bottom: 24px; */
  font-size: 16px;
  flex-grow: 1;
}

.tag-error {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;

  padding: 2px 6px;
  /* background: rgba(255, 86, 85, 0.14); */
  background: #ffe8e7;
  border-radius: 100px;

  font-weight: 500 !important;
  font-size: 12px;
  text-decoration: none;

  color: #ff5655 !important;

  position: absolute;
  bottom: 16px;
  right: 14px;
  opacity: 0;
}

.tag-error img {
  height: 12px;
}

.show {
  /* display: flex; */
  opacity: 1;
}

.char-counter {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;

  padding: 2px 6px;
  background: #f2f2f2;
  border-radius: 100px;

  font-weight: 500 !important;
  color: #7e7e7e;
  font-size: 12px;
  text-decoration: none;

  position: absolute;
  bottom: 16px;
  right: 14px;
  transition: 200ms all ease-in-out;
}

.input-icon-wrap {
  position: absolute;
  bottom: 14px;
  right: 14px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 6px;
  border-radius: 0.5rem;
  font-weight: 500;
  /* z-index: -1; */
}

.input-wrap label {
  position: absolute;
  top: -8px;
  left: 20px;

  font-size: 12px;
  font-weight: 600;
  color: #000000;

  padding: 0 4px;
  background-color: white;
}

.input {
  display: block;
  /* box-sizing: border-box; */
  padding: 12px;
  width: 100%;
  border: none;
  border: 2px solid #dcdcdc;
  border-radius: 1rem;
  font-family: inherit;
  resize: none;
  line-height: 1.75;
  transition: 100ms all ease-in-out;
}

.input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #c850c0 !important;
}

.input-error {
  transition: 100ms all ease-in-out;
  box-shadow: 0 0 0 2px #ff4342;
  /* border: 2px solid #ff4342; */
}

.input-error-message {
  /* display: none; */
  color: #ff4342;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 0 0 16px;
  transition: 100ms all ease-in-out;
}

.input-error:focus {
  box-shadow: 0 0 0 2px #ff4342;
  outline-offset: -2px;
}

.input-wrap-error label {
  color: #ff4342;
}

::-webkit-input-placeholder {
  color: #969696;
}

.input.small {
  height: 56px;
}

.input.large {
  height: 220px;
}

.reply-input-wrap {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
}

.nav-connect-button {
  cursor: pointer;
  padding: 12px 12px;
  line-height: 1;
  /* height: 54px; */
  border-radius: 0.8rem;
  background: #c850c0;
  font-size: 16px;
  font-weight: 600;
  width: 100%;

  color: #fff;
  transition: all 0.25s ease;
}

.nav-connect-button:hover {
  scale: 1.025;
}

.mint-button-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.button-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 8px;
}

.footer-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 13px;
}

.footer-info img {
  height: 12px;
}

.footer-info p {
  color: #7b7b7b;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 32px;

  font-size: 13px;
  font-weight: 400;
  color: #7b7b7b;
}

.footer a {
  color: #969696;
}

.mint-button {
  cursor: pointer;
  padding: 8px;
  height: 54px;
  border: 0;
  border-radius: 1rem;
  background: #c850c0;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin-top: -2px;

  color: #fff;
  transition: all 0.25s ease;
}

.mint-button:hover {
  scale: 1.025;
}

.disabled {
  background: #e0e0e0;
  color: #7e7e7e;
  cursor: not-allowed !important;
}

.disabled:hover {
  scale: 1 !important;
}

.connected {
  background: linear-gradient(
    45deg,
    #4158d0 -8.56%,
    #c850c0 50.53%,
    #ffcc70 113.44%
  );
}

.message-export {
  position: relative;
  /* padding: 4px; */
  overflow: hidden;
  scroll-behavior: none;
}

.message-wrap {
  display: flex;
  flex-direction: column;

  position: relative;
  width: 480px;
  height: 380px;
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;

  /* outline: 1px solid #dee2ee; */
  outline-offset: -1px;
}

.message-header {
  font-family: 'ES Rebond Grotesque';
  font-size: 20px;
  font-weight: 600;

  border-radius: 1rem 1rem 0 0;
  height: 64px;
  /* background: #c850c0; */
  background: linear-gradient(
    45deg,
    #4158d0 -8.56%,
    #c850c0 50.53%,
    #ffcc70 118.44%
  );
  /* outline: 2px solid #c850c0; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.message-header p {
  color: white;
  padding-bottom: 2px;
}

.message-body {
  padding: 20px 24px 24px 24px;
  border-top: 1px solid #fff !important;
  /* border: 1px solid #dee2ee; */
  outline-offset: -1px;
  border-radius: 0 0 1rem 1rem;
  flex-grow: 1;
  justify-content: space-between;
}

.message-from {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}

.from-left {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.from-left p:first-of-type {
  color: #7b7b7b !important;
}

.from-right {
  color: #7b7b7b;
}

.message-separator {
  width: 100%;
  height: 1px;
  background: #e0e0e0;
  margin: 20px 0 20px 0;
}

.message-message {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  line-height: 150%;
}

.message-footer {
  position: absolute;
  bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
  font-size: 14px;
}

.message-footer p:first-of-type {
  color: #7b7b7b;
  font-weight: 500;
  line-height: 100%;
}

.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;

  padding: 2px 8px;
  background: #f2f2f2;
  border-radius: 100px;

  font-weight: 500 !important;
  font-size: 14px;
  text-decoration: none;

  color: #7e7e7e;
}

.tag img {
  height: 10px;
}

.tag span {
  margin-bottom: 2.5px;
}

.tag-outline {
  background: none;
  border: 2px solid #efefef;
  transition: all 0.25s ease;
}

.tag-outline:hover {
  background: #f2f2f2;
  scale: 1.06;
}

.confirm-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 24px;

  position: relative;
  width: 480px;
  height: 380px;
  background-color: #fff;
  border-radius: 1rem;
  /* box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05); */
  overflow: hidden;

  outline: 1px solid #dee2ee;
  outline-offset: -1px;
}

.confirm-close-wrap {
  width: 100%;
  display: flex;
  justify-content: right;
}

.confirm-close {
  width: 28px;
  height: 28px;

  display: flex;
  flex-direction: column;
  padding: 9px;

  background: #f2f2f2;

  border-radius: 100%;
  outline: none;
}

.confirm-message {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
}

.confirm-links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.confirm-link {
  color: #7e7e7e;
  font-size: 14px;

  display: flex;
  flex-direction: row;
  gap: 4px;
}

.confirm-link img {
  width: 8px;
}

.animated-box {
  position: relative;
  transform-style: preserve-3d;
  width: 480px;
  height: 380px;
}

.confirm-wrap-mobile {
  display: none;
}

.banner {
  width: 100%;
  padding: 6px;
  background: #eb4d4b;
  display: none;
}

.banner p {
  color: #ffff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

@media (max-width: 1024px) {
  body {
    overflow: visible;
  }

  .left {
    position: relative;
    width: 100vw;
    padding-top: 40px;
    height: auto;
  }

  .right {
    position: relative;
    width: 100vw;
    padding: 40px 64px;
    height: auto;
  }

  .footer {
    margin-top: 24px;
  }
}

@media (max-width: 1280px) {
  .tag-error {
    padding: 6px 6px;
    z-index: 2;
  }

  .tag-error span {
    display: none;
  }
}

@media (max-width: 750px) {
  .banner {
    display: block;
  }

  /* .connect-nav-wrap {
    display: none;
  } */
  .left {
    padding: 40px 24px;
  }

  .right {
    padding: 40px 24px;
  }

  .nav-wrap {
    padding: 48px 24px;
  }

  .mint-button:hover {
    scale: 1;
  }

  .nav-connect-button:hover {
    scale: 1;
  }
}

@media (max-width: 480px) {
  html,
  body {
    overflow-x: hidden;
  }
  body {
    position: relative;
  }

  .message-export {
    scale: 0.7;
  }

  .connect-nav-wrap {
    font-size: 14px;
  }

  .logo-wrap h1 {
    font-size: 22px;
  }

  .left {
    padding: 32px 20px 48px 20px;
  }

  /* .nav-wrap {
    height: 10vh;
    z-index: 2;
  }

  .left {
    position: fixed;
    height: 90%;

    padding-bottom: 24px;
    margin-top: 10vh;

    z-index: 1;
    background: #fff;
  }

  .confirm-wrap-mobile {
    display: block;
  } 

  .flip-wrap {
    opacity: 0;
  }
  */
}
